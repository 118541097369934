import { Component } from "react";
import NoImage from "../../assets/img/no-image.png";

const ItemSlide = (props) => {
  return (
    <div className="item-slide">
      <div className="image-slide">
        

        {props.item.logo_url && (
            <img alt="img" src={props.item.logo_url} />
        )}

        {props.item.icon_url && (
            <img alt="img" src={props.item.icon_url} />
        )}


        

        {props.item.logo_url == null && props.item.icon_url == null && (
            <img className="no-image" alt="img" src={NoImage} />
        )}


      </div>
      <div className="text-slide">
        <p>{props.item.name}</p>
      </div>
    </div>
  );
};

export default ItemSlide;
