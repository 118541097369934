import { useParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Search from '../Searcher/Search'
import './ResultadosBusqueda.scss';

const ResultadosBusqueda = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const text = searchParams.get("text");
    const type_id = searchParams.get("type_id");
    const brand_id = searchParams.get("brand_id");
    const brand_model_id = searchParams.get("brand_id");



    return(
        <div>
            <Search type_id={type_id}  id={id} text={text} brand_id={brand_id} brand_model_id={brand_model_id}/>
        </div>
    )
}

export default ResultadosBusqueda;