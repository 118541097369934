import "./Modal.scss";
import { Modal, Button } from "react-bootstrap";
import { useState } from "react";

const ModalComoBusco = (params) => {
    const [showModalComoBusco, setShowModalComoBusco] = useState(false);
    const openModalComoBusco = () => setShowModalComoBusco(true);
    return (
        <div>
            <Modal className="como-busco" show={params.show} onHide={params.hide}>
                <Modal.Header closeButton>
                <Modal.Title>
                    <h2>¿Cómo <strong>busco?</strong></h2>
                    <p>Te compartimos el paso a paso para realizar una búsqueda efectiva en nuestra web.</p>
                </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <ul>
                        <li>
                           Filtrá tu búsqueda por rubro, marca o nombre de la empresa.
                        </li>

                        <li>
                        Realizá tu búsqueda para encontrar el rubro, marca o empresa que estás buscando.
                        </li>

                        <li>
                        Una vez generada la búsqueda, visualizarás el listado de los resultados de la misma pudiendo acceder a los datos de la empresa para contactarte directamente
                        </li>
                    </ul>
                </Modal.Body>

                
            </Modal>
        </div>
    )
}


export default ModalComoBusco;