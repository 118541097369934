import { Link, useNavigate } from "react-router-dom";
import "../../sass/app.scss";
import "./Searcher.scss";
import HeaderSerach from "../../assets/img/header-search.jpg";
import { Dropdown } from "react-bootstrap";
import IconBrand from "../../assets/img/icon-brand.svg";
import IconCar from "../../assets/img/icon-car.svg";
import IconServices from "../../assets/img/icon-services.svg";
import Autosuggest from "react-autosuggest";
import React, { useState, useEffect } from "react";

const Searcher = (props) => {
  const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [type, setType] = useState(null);
  const [brands, setBrands] = useState([]);
  const [modelosTemp, setModelosTemp] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [versions, setVersions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(0);
  const [selectedModelo, setSelectedModelo] = useState(0);
  const [selectedVersion, setSelectedVersion] = useState(0);
  

  useEffect(() => {
    setType(props.type);

    const getBrands = async () => {
      try {
        const response = await fetch("http://sd-1501408-h00022.ferozo.net/api/brands");
        const dataJson = await response.json();
        setBrands(dataJson.data.data);

        //console.log('brands ', brands)
      } catch (error) {
        console.log(error);
      }
    };

    getBrands();
  }, [props.type]);


  const getModelos = async (idMarca) => {
        if (idMarca > 0){
      console.log('id marca', idMarca);
      try {
        const response = await fetch(`http://sd-1501408-h00022.ferozo.net/api/brand_models/${idMarca}`);
        const dataJson = await response.json();
        setModelosTemp(dataJson.data);
      } catch (error) {
        console.log(error);
      }
    }    
  }

  useEffect(() => {
    console.log('modelos:', modelosTemp);
    setModelos(modelosTemp);
  }, [modelosTemp]);

  const getVersions = async (idVersion) => {    
    if (idVersion > 0){
      console.log('id version', idVersion);
      try {
        const response = await fetch(`http://sd-1501408-h00022.ferozo.net/api/brand_model_versions/${idVersion}`);
        const dataJson = await response.json();
        setVersions(dataJson.data);

        console.log('versions ', versions)
      } catch (error) {
        console.log(error);
      }
    }
  } 

  const handleSelectBrand = (event) => {
    console.log('target', event.target.value);
    const selectedValue = event.target.value;
    setSelectedBrand(selectedValue);
  };

  useEffect(() => {
    getModelos(selectedBrand);
  }, [selectedBrand]);

  const handleSelectModelo = (event) => {
    
    const selectedValue = event.target.value;
    setSelectedModelo(selectedValue);
  }

  useEffect(() => {
    getVersions(selectedModelo);
  }, [selectedModelo]);

  const handleSelectVersion = (event) => {
    const selectedValue = event.target.value;
    setSelectedVersion(selectedValue);
  }

  const getSuggestions = async (value) => {
    if (value.length > 2) {
      const response = await fetch(
        `http://sd-1501408-h00022.ferozo.net/public/api/search/suggestions?type_id=${props.type}&text=${value}`
      );
      const data = await response.json();
      setSuggestions(data.data);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    getSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    // Actualiza el estado de búsqueda con la sugerencia seleccionada
    setQuery(suggestion.name);
  };

  const renderSuggestion = (suggestion) => {
    return <div>{suggestion.name}</div>;
  };

  const inputProps = {
    placeholder: "Buscar...",
    value: query,
    onChange: (event, { newValue }) => setQuery(newValue),
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let url = `/resultados?type_id=${props.type}&text=${query}`;
    console.log('selectedBrand', selectedBrand);
    if (selectedBrand) {
      url += `&brand_id=${selectedBrand}`;
    }
    if (selectedModelo) {
      url += `&brand_model_id=${selectedModelo}`;
    }

    //selectedBrand
    //selectedModelo


    navigate(url);
  };

  return (
    <section className="section-searcher">
      <div className="container">
        <h1>BUSCADOR DE CASAS DE REPUESTOS Y SERVICIOS DEL RUBRO AUTOMOTOR</h1>
        <div className="nav-homes">
          <Link
            to="../repuestos"
            className={`btn-icon ${type == 1 ? "active" : ""}`}
          >
            <img className="icon" alt="Empresas" src={IconCar} />
            <span>
              REPUESTOS
              <br />
              PARA VEHÍCULOS
            </span>
          </Link>
          <Link
            to="../servicios"
            className={`btn-icon ${type == 2 ? "active" : ""}`}
          >
            <img className="icon" alt="Empresas" src={IconServices} />
            <span>
              SERVICIOS
              <br />
              PARA VEHÍCULOS
            </span>
          </Link>
          <Link
            to="../empresas"
            className={`btn-icon ${type == 3 ? "active" : ""}`}
          >
            <img className="icon" alt="Empresas" src={IconBrand} />
            <span>
              NOMBRE DE
              <br /> LA EMPRESA
            </span>
          </Link>
        </div>

        <div className="row">
          <div className="col-12 ">
            <form className="content-search" onSubmit={handleSubmit}>
              <div className="autocomplete">
                {(props.type === "1" || props.type === "2") && (
                  <div className="filters-search">
                    <div className="search-inputs">
                      <select className="form-select" value={selectedBrand} aria-label="" onInput={handleSelectBrand}>
                        <option value="0" >Marca</option>
                        {brands.map((item, index) => (
                        <option value={item.id} key={index}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="search-inputs">
                      <select className="form-select" value={selectedModelo} aria-label=""  onInput={handleSelectModelo}>
                        <option value="0" >Modelos</option>
                        {modelos.map((item, index) => (
                        <option value={item.id} key={index}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="search-inputs">
                      <select className="form-select" value={selectedVersion} aria-label=""  onInput={handleSelectVersion}>
                        <option value="0" >Versión</option>
                        {versions.map((item, index) => (
                        <option value={item.id} key={index}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="search-inputs">
                      <input type="number" className="form-control" id="anio" placeholder="Año" />
                    </div>                    
                  </div>
                )}
                <div className="input-box">
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionSelected={onSuggestionSelected}
                    getSuggestionValue={(suggestion) => suggestion.name}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                  />
                  <button type="submit" className="btn-buscar">
                    BUSCAR
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <img className="bg-img" src={HeaderSerach} />
    </section>
  );
};

export default Searcher;
