import "./Modal.scss";
import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import IconSend from "../../assets/img/icon-send.svg";
import { useFormik } from "formik";
import Personaje from "../../assets/img/personaje.png";

const ModalContacto = (params) => {
  const [showModalContacto, setShowModalContacto] = useState(false);

  const modalCloseContacto = () => setShowModalContacto(false);
  const openModalContacto = () => setShowModalContacto(true);

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [respuesta, setRespuesta] = useState(true);
  const [file, setFile] = useState(null);
  const [base64String, setBase64String] = useState("");
  const [nombreEmresa, setnombreEmresa] = useState("");

  useEffect(() => {
    
    if (params.items && params.items.length > 0) {
      setnombreEmresa(params.items[0].name);
    }
    
    
  }, [params.items]);

  const handleFileChange = (event) => {
    const fileUpload = event.target.files[0];
    console.log('handleFileChange');
    if (fileUpload instanceof File) {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        setBase64String(reader.result);
        // Aquí puedes utilizar el valor base64String como desees
        console.log(base64String);
      };
  
      reader.readAsDataURL(fileUpload);

      console.log('base64String', base64String);
    } else {
      console.log("No se seleccionó un archivo válido.");
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      cell_phone: "",
      phone: "",
      email: "",
      street_address: "",
      city: "",
      subject: "",
      text: "",
      companies:[1,2,3],
      file: undefined
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Es necesario ingresar un nombre y apellido";
      }

      if (values.cell_phone && values.cell_phone.length < 8) {
        errors.cell_phone = "Es necesario ingresar un celular";
      }

      if (values.phone && values.phone.length < 8) {
        errors.phone = "Es necesario ingresar un teléfono";
      }

      if (!values.email) {
        errors.email = "Es necesario ingresar un e-mail";
      }

      if (!values.street_address) {
        errors.street_address = "Es necesario ingresar una dirección";
      }

      if (!values.city) {
        errors.city = "Es necesario ingresar una localidad";
      }

      if (!values.subject) {
        errors.subject = "Es necesario ingresar un asunto";
      }

      if (!values.text) {
        errors.text = "Es necesario ingresar un mensaje";
      }

      return errors;
    },
    onSubmit: async (values) => {
      

      try {
        const formData = new FormData();
        if (base64String != "") {
          formData.append("file", base64String);
          

        
        }
        
        
        formData.append("name", values.name);
        formData.append("cell_phone", values.cell_phone);
        formData.append("phone", values.phone);
        formData.append("email", values.email);
        formData.append("street_address", values.street_address);
        formData.append("city", values.city);
        formData.append("subject", values.subject);
        formData.append("text", values.text);
        if (params.type == "empresas"){
          console.log(params.items.length)
          for (let index = 0; index < params.items.length; index++) {
            
            formData.append("companies[]", params.items[index].id);
            
          }      
        }
        //formData.append("file", values.file);

        let url_send = "";

        if (params.type == "don_consulta"){
          url_send = 'http://sd-1501408-h00022.ferozo.net/api/don_consulta_contact';
        }

        if (params.type == "empresas"){
          url_send = 'http://sd-1501408-h00022.ferozo.net/api/contact';
        }

        const response = await fetch(url_send, {
          method: 'POST',
          headers: {
            //"Accept": "application/json",
            //"Content-Type": "application/x-www-form-urlencoded"
          },
          body: formData,
        });

        if (response.ok) {
          // La solicitud se realizó correctamente
          // Puedes realizar acciones adicionales aquí, como mostrar un mensaje de éxito
        } else {
          // Ocurrió un error en la solicitud
          // Puedes manejar el error de la forma que desees, por ejemplo, mostrar un mensaje de error
        }
      } catch (error) {
        console.log(error);
      }    
    }
  });

  const validateEmail = (email) => {
    // Expresión regular para validar el formato del correo electrónico
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setRespuesta("");
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      setRespuesta("El formato del e-mail no es correcto.");
      return;
    }

    try {
      setIsValidEmail(true);
      setRespuesta("");

      //setRespuesta(data.message);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Modal show={params.show} onHide={params.hide}>
        <Modal.Header closeButton>
        {params.type == "don_consulta" && (
              <Modal.Title>
                <div className="title-image">
                  <div className="wrap-personaje">
                    <img alt="Personaje" src={Personaje} />
                  </div>
                  <div className="text">
                    <h2>Contactate con <strong>Don Consulta</strong></h2>
                    <p>Completá el siguiente formulario y él te dirá donde encontrar lo que precisás.</p>
                  </div>
                </div>
              </Modal.Title>
            )}

            {params.type == "empresas" && (
              <Modal.Title>
              <p>Completá el siguiente formulario para enviar tu consulta general a
                todas las casas de repuestos{" "}
                <strong>de la marca {nombreEmresa}.</strong></p>
            </Modal.Title>
            )}
          
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-6">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="nombreInput"
                    placeholder="Nombre y apellido"
                    {...formik.getFieldProps("name")}
                  />
                  <label htmlFor="nombreInput">Nombre y apellido</label>
                  {formik.touched.name && formik.errors.name ? (
                    <div className="error">{formik.errors.name}</div>
                  ) : null}
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="cell_phoneInput"
                    placeholder="Celular"
                    {...formik.getFieldProps("cell_phone")}
                  />
                  <label htmlFor="cell_phoneInput">Celular</label>
                  {formik.touched.cell_phone && formik.errors.cell_phone ? (
                    <div className="error">{formik.errors.cell_phone}</div>
                  ) : null}
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="tel"
                    className="form-control"
                    id="phoneInput"
                    placeholder="Teléfono"
                    {...formik.getFieldProps("phone")}
                  />
                  <label htmlFor="phoneInput">Teléfono</label>
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="error">{formik.errors.phone}</div>
                  ) : null}
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="E-mail"
                    {...formik.getFieldProps("email")}
                  />
                  <label htmlFor="floatingInput">E-mail</label>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                  ) : null}
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="direccionInput"
                    placeholder="Dirección"
                    {...formik.getFieldProps("street_address")}
                  />
                  <label htmlFor="direccionInput">Dirección</label>
                  {formik.touched.street_address && formik.errors.street_address ? (
                    <div className="error">{formik.errors.street_address}</div>
                  ) : null}
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="localidadInput"
                    placeholder="Localidad"
                    {...formik.getFieldProps("city")}
                  />
                  <label htmlFor="localidadInput">Localidad</label>
                  {formik.touched.city && formik.errors.city ? (
                    <div className="error">{formik.errors.city}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-xl-6">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="asuntoInput"
                    placeholder="Asunto"
                    {...formik.getFieldProps("subject")}
                  />
                  <label htmlFor="asuntoInput">Asunto</label>
                  {formik.touched.subject && formik.errors.subject ? (
                    <div className="error">{formik.errors.subject}</div>
                  ) : null}
                </div>

                <div className="form-floating textarea">
                  <textarea
                    className="form-control"
                    placeholder="Dejá tu mensaje..."
                    id="mensajeInput"
                    {...formik.getFieldProps("text")}
                  ></textarea>
                  <label htmlFor="mensajeInput">Dejá tu mensaje...</label>
                  {formik.touched.text && formik.errors.text ? (
                    <div className="error">{formik.errors.text}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-xl-12">
                <div className="form-floating mb-3 upload">
                  <input
                    type="file"
                    className="form-control"
                    id="archivoInput"
                    onChange={handleFileChange}
                                   
                   
                  />
                  <label htmlFor="archivoInput"><span>Adjuntar Archivo</span></label>
                  {formik.touched.file && formik.errors.file ? (
                    <div className="error">{formik.errors.file}</div>
                  ) : null}
                </div>
              </div>

              <button type="submit" className="btn-send">
                <img className="icon" src={IconSend} /> Enviar
              </button>
              <div className="col-xl-12">
                <p>{respuesta}</p>
              </div>
            </div>
          </form>
        </Modal.Body>

        
      </Modal>
    </div>
  );
};

export default ModalContacto;
