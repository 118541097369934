import React, { useState, useEffect } from "react";
import "./Search.scss";
//import '../../assets/icons/icons.css';
import { Link } from "react-router-dom";
import ModalContacto from "../Modals/ModalContacto";
import BannerConsultaMarcas from "../Banner/BannerConsultaMarcas";
import Searcher from "../Searcher/Searcher";

const Search = (props) => {
  const [data, setData] = useState([]);
  const [showModalContacto, setShowModalContacto] = useState(false);
  const [arrItemOpen, setArrItemOpen] = useState([]);

  const modalCloseContacto = () => setShowModalContacto(false);
  const openModalContacto = (item) => {
    const newArray = [item]; // Crear una copia del array actual
    // newArray.push(item); // Agregar el nuevo elemento a la copia del array
    setArrItemOpen(newArray);

    setShowModalContacto(true);
  };

  useEffect(() => {
    fetchData();
  }, [props.id]);

  const goWhatsApp = (phone) => {
    alert(phone);
  };
  const fetchData = async () => {
    console.log("fetchData");

    let url = "http://sd-1501408-h00022.ferozo.net/api/search?";

    if (props.type_id) {
      url += `type_id=${props.type_id}&`;
    }

    if (props.id) {
      url += `id=${props.id}&`;
    }

    if (props.text) {
      url += `text=${props.text}&`;
    }

    if (props.brand_id) {
      url += `brand_id=${props.brand_id}&`;
    }

    if (props.brand_model_id) {
      url += `brand_model_id=${props.brand_model_id}&`;
    }

    // Eliminar el último '&' si existe
    if (url.endsWith("&")) {
      url = url.slice(0, -1);
    }

    const response = await fetch(url);

    const json = await response.json();

    if (json.status === 1) {
      //setData(json.data);
      const modifiedData = json.data.map((item) => {
        if (item.phones) {
          const phones = item.phones;
          for (let i = 0; i < phones.length; i++) {
            const element = phones[i];
            if (element.phone_type_id === "2") {
              item.celular = element.phone_number;
            }
            if (element.phone_type_id === "1") {
              item.telefono = element.phone_number;
            }
            if (element.phone_type_id === "6") {
              item.whatsapp = element.phone_number;
            }
          }
        }
        //console.log(item);
        return item;
      });

      setData(modifiedData);
    } else {
      console.log("Error");
    }
  };
  return (
    <div>
      {data.length > 0 ? (
        <div className="container">
          <BannerConsultaMarcas type="empresas" items={data} />
        </div>
      ) : (
        <div>
          <Searcher key="2" type={props.type_id} />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="text-center mt-4 mb-5">
                  No se encontraron resultados
                </h1>
              </div>
            </div>
          
          </div>
        </div>
      )}

      <div className="container">
        <ModalContacto
          show={showModalContacto}
          hide={modalCloseContacto}
          type="empresas"
          items={arrItemOpen}
        />

        <div className="grid-search-results">
          {data.map((item, index) => (
            <div className="item-search" key={index}>
              <picture className="image">
                <img
                  alt="img"
                  src={`https://www.eltoquedewarnes.com.ar/uploads/empresas/original/${item.photo1}`}
                />
              </picture>
              <div className="info">
                <h3>{item.name}</h3>
                <div className="destacado">
                  <Link to={"../empresa/" + item.id} className="btn btn-perfil">
                    <div>
                      <i className="icon icon-user"></i>
                      <span>Ver perfil</span>
                    </div>
                  </Link>
                  <button
                    className="btn btn-mail"
                    onClick={() => openModalContacto(item)}
                  >
                    <div>
                      <i className="icon icon-mail"></i>
                      <span>Enviar mail</span>
                    </div>
                  </button>

                  {item.whatsapp && (
                    <button
                      className="btn btn-whatsapp"
                      onClick={() => goWhatsApp(item.whatsapp)}
                    >
                      <div>
                        <i className="icon icon-whatsapp"></i>
                        <span>Whatsapp</span>
                      </div>
                    </button>
                  )}
                </div>

                <ul className="social-list">
                  {item.whatsapp && (
                    <li>
                      <a
                        className="btn btn-whatsapp"
                        href={" https://wa.me/" + item.whatsapp}
                        target="_blank"
                      >
                        <div>
                          <i className="icon icon-whatsapp"></i>
                        </div>
                      </a>
                    </li>
                  )}

                  {item.facebook && (
                    <li>
                      <button className="btn btn-facebook">
                        <a href={item.facebook} target="_blank">
                          <i className="icon icon-facebook"></i>
                        </a>
                      </button>
                    </li>
                  )}

                  {item.instagram && (
                    <li>
                      <button className="btn btn-instagram">
                        <a href={item.instagram} target="_blank">
                          <i className="icon icon-instagram"></i>
                        </a>
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Search;
