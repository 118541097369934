import SimpleSlider from "../Slider/SimpleSlider";
import React, { useState, useEffect } from "react";
import Searcher from "../Searcher/Searcher";
import DestacadoList from "../Home/DestacadoList";
import Destacado from "../Destacado/Destacado";
import Banner from "../Banner/Banner";
import ImageBanner1 from "../../assets/img/banner-1.jpg";
import ImageBanner2 from "../../assets/img/banner-2.jpg";

const HomeRepuestos = () => {
  const [data, setData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [services, setServices] = useState([]);
  const [accesories, setAccesories] = useState([]);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "http://sd-1501408-h00022.ferozo.net/api/search/importants/1"
      );
      const json = await response.json();
      setBrands(json.data.slider_1);
      setServices(json.data.slider_2);
      setAccesories(json.data.slider_3);
    };
    fetchBanners();
    fetchData();
  }, []);

  const fetchBanners = async () => {
    const response = await fetch(
      "http://sd-1501408-h00022.ferozo.net/api/banners?origin=accesories_home"
    );

    const json = await response.json();
    setBanners(json.data);
    console.log("banners", banners);
  }

  return (
    <div>
      <Searcher key="1" type="1" />

      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <div className="head-msg">
                <p>ENCONTRÁ TODOS LOS LOCALES DE WARNES EN UN SOLO LUGAR</p>
              </div>
            </div>
            {brands.length > 0 && (
              <div className="col-12">
                <SimpleSlider
                  data={brands}
                  layout="images"
                  title={`Casas de repuestos especialistas en: `}
                />
              </div>
            )}

            {services.length > 0 && (
              <div className="col-12">
                <SimpleSlider
                  data={services}
                  layout="full-image"
                  title={`Casas de repuestos especializadas en:`}
                />
              </div>
            )}

            <DestacadoList />

            {banners.d && banners.d[0] &&(
            <Banner
              image={banners.d[0].image}
              link={banners.d[0].link}
            />
            )}

            {banners.d && banners.d[1] && (
            <Banner
              image={banners.d[1].image}
              link={banners.d[1].link}
            />
            )}

            {accesories.length > 0 && (
              <div className="col-12">
                <SimpleSlider
                  data={accesories}
                  layout="icons"
                  title={`¿Buscás accesorios?`}
                />
              </div>
            )}
          </div>
        </div>
        
        {brands.length > 0 && (
        <div className="container-fluid">
          <div className="row">
            <Destacado data={brands} title={`Repuestos más buscados`} />
          </div>
        </div>
        )}

        <div className="container">
          <div className="row">

            {/* banner 2 */}
            {banners.d && banners.d[2] && (
              <div className="d-none d-md-block">
            <Banner
              image={banners.d[2].image}
              link={banners.d[2].link}
            />
              </div>
            )}

            {banners.m && banners.m[2] && (
              <div className="d-block d-md-none">
              <Banner
                image={banners.m[2].image}
                link={banners.m[2].link}
              />
              </div>
              )}
            {/* end banner 2 */}
            {banners.d && banners.d[3] && (
              <div className="d-none d-md-block">
                <Banner
                  image={banners.d[3].image}
                  link={banners.d[3].link}
                />
              </div>
            )}
            {/* banner 3 */}
            {banners.m && banners.m[3] && (
              <div className="d-block d-md-none">
              <Banner
                image={banners.m[3].image}
                link={banners.m[3].link}
              />
              </div>
              )}
              {/* end banner 3 */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeRepuestos;
