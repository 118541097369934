import { Component } from "react";
import SimpleSlider from "../Slider/SimpleSlider";
import React, { useState, useEffect } from "react";
import Searcher from "../Searcher/Searcher";
import DestacadoList from "../Home/DestacadoList";
import Banner from "../Banner/Banner";
import BannerAsesoramiento from "../Banner/BannerAsesoramiento";
import ImageBanner1 from "../../assets/img/banner-1.jpg";
import ImageBanner2 from "../../assets/img/banner-2.jpg";
import ImageBanner3 from "../../assets/img/banner-3.jpg";
const HomeServicios = () => {
  const [brands, setBrands] = useState([]);
  const [services, setServices] = useState([]);
  const [accesories, setAccesories] = useState([]);
  const [banners, setBanners] = useState([]);


  const fetchBanners = async () => {
    const response = await fetch(
      "http://sd-1501408-h00022.ferozo.net/api/banners?origin=services_home"
    );

    const json = await response.json();
    setBanners(json.data);
    console.log("banners", banners);
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "http://sd-1501408-h00022.ferozo.net/api/search/importants/2"
      );
      const json = await response.json();
      setBrands(json.data.slider_1);
      setServices(json.data.slider_2);
      setAccesories(json.data.slider_3);
    };
    fetchBanners();
    fetchData();
  }, []);

  return (
    <div>
      <Searcher key="2" type="2" />

      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="head-msg">
                <p>ENCONTRÁ TODOS LOS LOCALES DE WARNES EN UN SOLO LUGAR</p>
              </div>
            </div>
            {/* banner 0 */}
            {banners.d && banners.d[0] && (
              <div className="d-none d-md-block">
            <Banner
              image={banners.d[0].image}
              link={banners.d[0].link}
            />
              </div>
            )}

            {banners.m && banners.m[0] && (
              <div className="d-block d-md-none">
              <Banner
                image={banners.m[0].image}
                link={banners.m[0].link}
              />
              </div>
              )}
            {/* end banner 0 */}

            {brands.length > 0 && (
              <div className='col-12'>
                <SimpleSlider data={brands} layout="images" title={`Casas de repuestos especialistas en: `} />
              </div>
            )}  

            {services.length > 0 && (
              <div className='col-12'>
                <SimpleSlider data={services} layout="icons" title={`¿Buscás repuestos o accesorios para tu vehículo?`} />
              </div>
            )}

            <DestacadoList data={services} title={`Repuestos más buscados`}/>

            {/* banner 1 */}
            {banners.d && banners.d[1] && (
              <div className="d-none d-md-block">
            <Banner
              image={banners.d[1].image}
              link={banners.d[1].link}
            />
              </div>
            )}

            {banners.m && banners.m[1] && (
              <div className="d-block d-md-none">
              <Banner
                image={banners.m[1].image}
                link={banners.m[1].link}
              />
              </div>
              )}
            {/* end banner 1 */}

            {/* banner 2 */}
            {banners.d && banners.d[2] && (
              <div className="d-none d-md-block">
            <Banner
              image={banners.d[2].image}
              link={banners.d[2].link}
            />
              </div>
            )}

            {banners.m && banners.m[2] && (
              <div className="d-block d-md-none">
              <Banner
                image={banners.m[2].image}
                link={banners.m[2].link}
              />
              </div>
              )}
            {/* end banner 2 */}

            {/* banner 3 */}
            {banners.d && banners.d[3] && (
              <div className="d-none d-md-block">
            <Banner
              image={banners.d[3].image}
              link={banners.d[3].link}
            />
              </div>
            )}

            {banners.m && banners.m[3] && (
              <div className="d-block d-md-none">
              <Banner
                image={banners.m[3].image}
                link={banners.m[3].link}
              />
              </div>
              )}
            {/* end banner 3 */}

            {/* banner 4 */}
            {banners.d && banners.d[4] && (
              <div className="d-none d-md-block">
            <Banner
              image={banners.d[4].image}
              link={banners.d[4].link}
            />
              </div>
            )}

            {banners.m && banners.m[4] && (
              <div className="d-block d-md-none">
              <Banner
                image={banners.m[4].image}
                link={banners.m[4].link}
              />
              </div>
              )}
            {/* end banner 4 */}
          </div>
        </div>
      </section>
     
    </div>
  );
};

export default HomeServicios;
