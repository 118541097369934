import { Component } from 'react'
import Slider from "react-slick";
import ItemSlide from './ItemSlide';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SimpleSlider.scss';


const SimpleSlider = (props) => {

  const data = props.data;
  console.log('data ', data);
    var settings = {
      dots: false,
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1450,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        }
      ]
    };
    return(
        <div className={'slider-one '+props.layout}>
          <h1>{props.title}</h1>
          <Slider {...settings}>
            {Object.keys(data).map((key) => (
              <ItemSlide key={data[key].id} item={data[key]}/>
            ))}
          </Slider>
        </div>
      )
    
  }
  
  export default SimpleSlider;