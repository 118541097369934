import { Component } from "react";
import Container from "react-bootstrap/Container";
import {  Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import "../../sass/app.scss";
import "./Header.scss";
import Logo from "../../assets/img/logo.svg";

const Header = ({ openModalContacto , openModalComoBusco}) => {
  

  return (
    <header>
       <Navbar collapseOnSelect expand="lg" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <img alt="El toque de Warnes" src={Logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="">
              <button className="btn-rounded"  onClick={openModalComoBusco}>
                Cómo busco
              </button>
              <Link to='../publicar' className='btn-rounded'>Publicar</Link>
              <Nav.Link href="/noticias" className="btn-rounded">
                Noticias
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
