import { useParams } from 'react-router-dom'
import Search from '../Searcher/Search'

const Rubro = () => {
    const { id } = useParams();
    return(
        
        <div>
         <Search type_id="2"  id={id}/>
        </div>

        
    )
}

export default Rubro;