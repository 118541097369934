import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/logo.svg";
import Personaje from "../../assets/img/personaje.png";
import ModalContacto from "../Modals/ModalContacto";
const BannerAsesoramiento = () => {
  const [showModalContacto, setShowModalContacto] = useState(false);

  const modalCloseContacto = () => setShowModalContacto(false);
  const openModalContacto = () => setShowModalContacto(true);

  return (
    <div className="banner-asesoramiento col-12">
      <ModalContacto show={showModalContacto} hide={modalCloseContacto} type="don_consulta"/>
      <div className="wrap">
        <div className="col-one">
          <p>
            ¿NECESITÁS ASESORAMIENTO EN LA BÚSQUEDA DE REPUESTOS O SERVICIOS?
            DON CONSULTA PUEDE AYUDARTE
          </p>
          <div className="wrap-personaje">
            <img alt="Personaje" src={Personaje} />
          </div>
        </div>
        <div className="col-two">
          <div className="wrapTwo">
            <div className="buttons-group">
              <button className="btn btn-secondary red" onClick={openModalContacto}>Enviar mail</button>
              <button className="btn btn-secondary green">
                Enviar WHATSAPP
              </button>
            </div>
            <img className="logo" alt="El toque de Warnes" src={Logo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerAsesoramiento;
