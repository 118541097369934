import './SobreNosotros.scss';
import Title from "../Title/Title";

const SobreNosotros = ({ openModalContacto }) => {
    return(
        <div>       
        
        <section className="section-info sobre-nosotros">
        <div className='container'>
          <div className='row'>
            <Title text="Sobre nosotros"/>
            

            <div className="col-12">
                <p><b>El Toque de Warnes</b> es el portal líder en la búsqueda de casas de repuestos y servicios para el automotor. Con más de 20 años de experiencia en el rubro, nos consolidamos como marca pionera en la búsqueda de repuestos, accesorios, autopartes y servicios para el automotor.</p>
                <p>Nuestro objetivo es que el usuario encuentre el repuesto, accesorio, autoparte y servicio que precise para su vehículo sin importar la marca o modelo. De no poder encontrarlo, lo asesoraremos en su búsqueda, hasta que encuentre lo que necesite.</p>
                <p><b>El Toque de Warnes</b> es referente en el rubro, siendo el único medio/portal que invierte en la participación con stand propio en las exposiciones más importantes de la industria automotriz (Automechanika Frankfurt, Automechanika Buenos Aires, Automec San Pablo, Salón Internacional del Automóvil Buenos Aires, y más).</p>
            </div>           
          </div>

          <div className='row row-cards'>
            <div className="col-md-4 ">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Nuestra misión</h5>
                        <p>Que toda persona que tenga vehículo encuentre el repuesto, accesorio, autoparte y servicio que precise.</p>
                    </div>
                </div>
            </div>

            <div className="col-md-4 ">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Nuestra misión</h5>
                        <p>Que toda persona que tenga vehículo encuentre el repuesto, accesorio, autoparte y servicio que precise.</p>
                    </div>
                </div>
            </div>

            <div className="col-md-4 ">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Nuestra misión</h5>
                        <p>Que toda persona que tenga vehículo encuentre el repuesto, accesorio, autoparte y servicio que precise.</p>
                    </div>
                </div>
            </div>

            <div className="col-12">
            <div className="separate mt-5 mb-5"></div>
            </div>

            <div className="col-md-12 ">
                <div className='grid-two-cols'>
                    <div>
                        <div className="title-component">
                            <h1>Publicar en El Toque de Warnes</h1>
                        </div>

                        <p>Si estás interesado en publicar tu empresa en nuestro sitio web, no dudes en ponerte en contacto con nosotros y un asesor comercial se comunicará con vos a la brevedad</p>
                        <button className="btn btn-primary">Quiero publicar</button>
                    </div>
                    <div>
                        <div className="title-component">
                            <h1>Contactate con nosotros</h1>
                        </div>

                        <p>Si tenés alguna sugerencia, no dudes en ponerte en contacto con nosotros y un representante de nuestro equipo se comunicará con vos a la brevedad</p>
                        <button className="btn btn-primary"  onClick={openModalContacto}>Enviar mensaje</button>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="separate"></div>
            </div>
            
            <div className="col-md-5 ">
                <p><b>El Toque de Warnes</b> es una marca de Getres S.A. Agradecemos que confíe en nosotros.<br></br><br></br>Getres S.A.</p>
            </div>

            <div className="col-md-7">
                <div className="card">
                    <div className="card-body">
                        <div className="grid-propietarios">
                            <div><p><b>Propietario</b><br></br>Getres S.A.</p></div>
                            <div><p><b>Director</b><br></br>Alberto Glait</p></div>
                            <div><p><b>Redacción</b><br></br>Maturín 2408, Of. B | C.A.B.A..</p></div>
                        </div>
                        
                    </div>
                </div>
            </div>

          </div>

        </div>
      </section>
      </div>
      )
  }


export default SobreNosotros;