import { Component } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Header from "./components/Header/Header";
import HomeRepuestos from "./components/Home/HomeRepuestos";
import HomeEmpresas from "./components/Home/HomeEmpresas";
import HomeServicios from "./components/Home/HomeServicios";
import SobreNosotros from "./components/SobreNosotros/SobreNosotros";
import Footer from "./components/Footer/Footer";
import Rubro from "./components/Rubro/Rubro";
import Contacto from "./components/Contacto/Contacto";
import Publicar from "./components/Contacto/Publicar"
import Marcas from "./components/Marcas/Marcas";
import Rubros from "./components/Rubros/Rubros";
import ResultadosBusqueda from "./components/ResultadosBusqueda/ResultadosBusqueda";
import Empresa from "./components/Empresa/Empresa";
import ModalContacto from "./components/Modals/ModalContacto";
import ModalComoBusco from "./components/Modals/ModalComoBusco";
import { useState } from "react";

const App = () => {
  const [showModalContacto, setShowModalContacto] = useState(false);
  const [showModalComoBusco, setShowModalComoBusco] = useState(false);

  const modalCloseContacto = () => setShowModalContacto(false);
  const openModalContacto = () => setShowModalContacto(true);

  const modalCloseComoBusco = () => setShowModalComoBusco(false);
  const openModalComoBusco = () => setShowModalComoBusco(true);

  return (
    <div>

      <ModalContacto show={showModalContacto} hide={modalCloseContacto}/>
      <ModalComoBusco show={showModalComoBusco} hide={modalCloseComoBusco}/>
      <BrowserRouter>
        <Header openModalContacto={openModalContacto} openModalComoBusco={openModalComoBusco} />
        <Routes>
          <Route exact path="/" element={<HomeRepuestos />} />
          <Route exact path="/repuestos" element={<HomeRepuestos />} />
          <Route exact path="/empresas" element={<HomeEmpresas />} />
          <Route exact path="/servicios" element={<HomeServicios />} />
          <Route
            exact
            path="/sobre-nosotros"
            element={<SobreNosotros openModalContacto={openModalContacto} />}
          />
          <Route exact path="/contacto" element={<Contacto />} />
          <Route exact path="/publicar" element={<Publicar />} />
          <Route exact path="/rubros" element={<Rubros />} />
          <Route exact path="/marcas" element={<Marcas />} />
          <Route path="/rubros/:id" element={<Rubro />} />
          <Route path="/empresa/:id" element={<Empresa />} />
          <Route exact path="/resultados" element={<ResultadosBusqueda  />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
