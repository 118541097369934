import Check from '../../assets/img/check.svg';
const DestacadoList = () => {


    return(
     
        <div className="col-12">
        <div className='row destacado-1'>
                <div className='col-md-4'>
                <h2>¿Porqué utilizar<br/> El Toque de Warnes?</h2>                          
                </div>

                <div className='col-md-8'>
                <ul className='list-1'>
                    <li><img width="100%" className="icon" src={Check}/>Tiene un buscador simple y sencillo para que puedas encontrar lo que estás buscando.</li>
                    <li><img width="100%" className="icon" src={Check}/>Contacto directo y personalizado con el profesional de la casa de repuesto o taller que te asesorará en tu búsqueda.</li>
                    <li><img width="100%" className="icon" src={Check}/>Si no encontrás lo que estás buscando o necesitás asesoramiento, te ayudamos en la búsqueda.</li>
                </ul>                          
                </div>
            </div>
          </div>
      
        
      )
  
}

export default DestacadoList;