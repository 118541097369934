import React, { useState, useEffect } from "react";
import ModalContacto from "../Modals/ModalContacto";

const BannerConsultaMarcas = (props) => {
    const [showModalContacto, setShowModalContacto] = useState(false);
    const [typeBanner, setTypeBanner] = useState([]);
    const [arrItemOpen, setArrItemOpen] = useState([]);
    const modalCloseContacto = () => setShowModalContacto(false);

    useEffect(() => {
        console.log(props.items);
        setArrItemOpen(props.items);
        setTypeBanner(props.type);
      }, [props.items]);

    const openModalContacto = () => {
        console.log(arrItemOpen);
        
        setShowModalContacto(true);
    }

    return (
        <div className="row">
            <ModalContacto show={showModalContacto} hide={modalCloseContacto} type={typeBanner} items={arrItemOpen} />
            <div className="col-12 ">
                <div className="banner-marcas">
                    <div className="col-text">
                        <div>
                        <h2>¡AHORRÁ TIEMPO!</h2>
                        <p>Con un solo click, envía tu consulta general a <b>todos los locales</b>.</p>
                        </div>
                    </div>

                    <div className="col-button">
                        <button className="btn btn-primary" onClick={() => openModalContacto()}><i className="icon-mail"></i>Enviar consulta general</button>
                    </div>
                </div>
            
            </div>
            
        </div>
    );
}

export default BannerConsultaMarcas;