import { Component } from "react";
import React, { useState } from "react";
import Title from "../Title/Title";
import IconSend from '../../assets/img/icon-send.svg';
import "./Contacto.scss";
import { useFormik } from "formik";
import * as Yup from 'yup';

const Publicar = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [respuesta, setRespuesta] = useState(true);

  const validateEmail = (email) => {
    // Expresión regular para validar el formato del correo electrónico
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const formik = useFormik({
      initialValues:{
        name: "",
        phone: "",
        email: "",
        company: "",
        subject: "",
        text: "",
        type_id: 2
      },
      validate: (values) => {
        const errors = {};
        if (!values.name) {
          errors.name = "Es necesario ingresar un nombre y apellido";
        }

        if (!values.phone) {
          errors.phone = "Es necesario ingresar un teléfono";
        }

        if (!values.email) {
          errors.email = "Es necesario ingresar un e-mail";
        }else if (!validateEmail(values.email)){
          errors.email = "Es necesario ingresar un e-mail válido";
        }

        if (!values.company) {
          errors.company = "Es necesario ingresar una empresa";
        }

        if (!values.subject) {
          errors.subject = "Es necesario ingresar un motivo";
        }

        if (!values.text) {
          errors.text = "Es necesario ingresar un mensaje";
        }

        return errors;
      },
      onSubmit: async (values, { resetForm }) => {
        try {
          const formData = new FormData();
          
          formData.append("name", values.name);
          formData.append("phone", values.phone);
          formData.append("email", values.email);
          formData.append("company", values.company);
          formData.append("subject", values.subject);
          formData.append("text", values.text);
          formData.append("type_id", 2);


          const response = await fetch("http://sd-1501408-h00022.ferozo.net/api/contact_warnes", {
            method: 'POST',
            headers: {
              //"Accept": "application/json",
              //"Content-Type": "application/x-www-form-urlencoded"
            },
            body: formData,
          });
  
          if (response.ok) {
            resetForm({values: ''});
            setRespuesta("La solicitud se realizó correctamente");
            // La solicitud se realizó correctamente
            // Puedes realizar acciones adicionales aquí, como mostrar un mensaje de éxito
          } else {
            // Ocurrió un error en la solicitud
            // Puedes manejar el error de la forma que desees, por ejemplo, mostrar un mensaje de error
          }
        }catch (error) {
          console.log(error);
        }  
      }
    
    });

  

  

  return (
    <section className="section-info contacto">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Title text="Publique en El Toque de Warnes" />
            <p>
              <b>El Toque de Warnes</b> es el portal líder en la búsqueda de casas de repuestos y servicios para el automotor. Con más de 20 años de experiencia en el rubro, nos consolidamos como marca pionera en la búsqueda de repuestos, accesorios, autopartes y servicios para el automotor.</p>
              <p>Nuestro objetivo es que el usuario encuentre el repuesto, accesorio, autoparte y servicio que precise para su vehículo sin importar la marca o modelo. De no poder encontrarlo, lo asesoraremos en su búsqueda, hasta que encuentre lo que necesite.</p>
              <p>El Toque de Warnes es referente en el rubro, siendo el único medio/portal que invierte en la participación con stand propio en las exposiciones más importantes de la industria automotriz (Automechanika Frankfurt, Automechanika Buenos Aires, Automec San Pablo, Salón Internacional del Automóvil Buenos Aires, y más).           </p>
          </div>
          <div className="col-md-6">
          <form onSubmit={formik.handleSubmit}>
              <Title text="Completá el formulario" subtext="Y un asesor comercial se comunicará con vos a la brevedad" />
              <div className="row">
                <div className="col-xl-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="nombreInput"
                      placeholder="name@example.com"
                      {...formik.getFieldProps("name")}
                    />
                    <label htmlFor="nombreInput">Nombre y apellido</label>
                    {formik.touched.name && formik.errors.name ? (
                    <div className="error">{formik.errors.name}</div>
                  ) : null}
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="tel"
                      className="form-control"
                      id="telefonoInput"
                      placeholder="Teléfono / Celular"
                      {...formik.getFieldProps("phone")}
                    />
                    <label htmlFor="telefonoInput">Teléfono / Celular</label>
                    {formik.touched.phone && formik.errors.phone ? (
                    <div className="error">{formik.errors.phone}</div>
                  ) : null}
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInput"
                      placeholder="E-mail"
                      value={email}
                      {...formik.getFieldProps("email")}
                    />
                    <label htmlFor="floatingInput">E-mail</label>
                    {formik.touched.email && formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                  ) : null}
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="empresaInput"
                      placeholder="Empresa"
                      {...formik.getFieldProps("company")}
                    />
                    <label htmlFor="empresaInput">Empresa</label>
                    {formik.touched.company && formik.errors.company ? (
                    <div className="error">{formik.errors.company}</div>
                  ) : null}
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="motivoInput"
                      placeholder="Motivo"
                      {...formik.getFieldProps("subject")}
                    />
                    <label htmlFor="motivoInput">Motivo</label>
                    {formik.touched.subject && formik.errors.subject ? (
                    <div className="error">{formik.errors.subject}</div>
                  ) : null}
                  </div>

                  <div className="form-floating textarea">
                    <textarea
                      className="form-control"
                      placeholder="Dejá tu mensaje..."
                      id="mensajeInput"
                      {...formik.getFieldProps("text")}
                    ></textarea>
                    <label htmlFor="mensajeInput">Dejá tu mensaje...</label>
                    {formik.touched.text && formik.errors.text ? (
                    <div className="error">{formik.errors.text}</div>
                  ) : null}
                  </div>
                </div>
                <div className="col-xl-12">
                    <p>{respuesta}</p>
                </div>
                <div className="col-xl-12">
                    <button type="submit" className="btn-send"><img className="icon" src={IconSend}/> Enviar</button>
                </div>
                
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Publicar;
