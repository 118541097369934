import "./Destacado.scss";
import NoImage from "../../assets/img/no-image.png";

const Destacado = (props) => {
  const data = props.data;
  return (
    <div className="col-12 destacado-block">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>{props.title}</h1>
            <div className="grid">
              {Object.entries(data).map(([index, elementos]) => (
                <div className="item" key={index}>
                  <div className="content-image">

                  {elementos.icon_url && (
                    <img alt="img" src={elementos.icon_url} />
                  )}

                  {elementos.icon_url == null && (
                      <img className="no-image" alt="img" src={NoImage} />
                    )}

                    </div>
                  <p className="name">{elementos.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Destacado;
