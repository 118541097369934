import "./ListItems.scss";
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "./Pagination";
import Title from "../Title/Title";
import { Link} from 'react-router-dom';

const ListItems = (props) => {
  const [data, setData] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [API_URL, setAPI_URL] = useState([]);
  var gruposRenderizados = null;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("page");



  useEffect(() => {
    console.log('fetchData')
    fetchData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    
    if (data.length > 0) {
      makeList();
    }
  }, [data]);

  const fetchData = async () => {
    console.log('fetchData 2')
    let page = currentPage;
    if (currentPage == null) {
      page = 1;
    }

   

    const response = await fetch(`${props.api}?page=${page}`);
    const json = await response.json();
    setData(json.data.data);

    setPaginationData({
      firstPageUrl: json.data.firstPageUrl,
      from: json.data.current_page,
      current_page: json.data.current_page,
      lastPage: json.data.last_page
    });
  };
  
  function makeList(){
    const gruposMake = data.reduce((acc, item) => {
      const inicial = item.name.charAt(0).toUpperCase();
      
      // Si el grupo de la inicial no existe, lo creamos
      if (!acc[inicial]) {
        acc[inicial] = [];
      }
  
      // Agregamos el elemento al grupo correspondiente
      acc[inicial].push(item);
  
      return acc;
    }, {});

    setGrupos(gruposMake);    
  }

  const handlePageChange = (page) => {
    const url = `/rubros?page=${page}`;
    navigate(url);
  };

  const navigate = useNavigate();

  return (
    <div>
      <section className="section-info sobre-nosotros">
        <div className="container">
          <div className="row">
           
            <Title text="Todos los Rubros"/>
            <div className="col-12 ">
              <div className="list-items">
             

                {Object.entries(grupos).map(([inicial, elementos]) => (
                  <div className="letter-list" key={inicial}>
                    <div className="inicial"><h3>{inicial}</h3></div>
                    <ul>
                      {elementos.map((elemento, index) => (
                        <li key={index}>
                            <Link to={'../rubros/'+elemento.id}  className='item-link'>
                              {elemento.name}
                            </Link>
                          </li>
                      ))}
                    </ul>
                  </div>
                ))}

              
              </div>

              <Pagination
                      firstPageUrl={paginationData.firstPageUrl}
                      current_page={paginationData.current_page}
                      lastPage={paginationData.lastPage}
                      lastPageUrl={data.lastPageUrl}
                      slug="rubros"
                      onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ListItems;
