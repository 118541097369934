import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Title from "../Title/Title";
import Slider from "react-slick";
import "./Empresa.scss";
import ModalContacto from "../Modals/ModalContacto";

const Empresa = () => {
  const { id } = useParams();
  const [empresa, setEmpresa] = useState([]);
  const [celular, setCelular] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [web, setWeb] = useState(null);
  const [whatsapp, setWhatsapp] = useState(null);

  const [showModalContacto, setShowModalContacto] = useState(false);

  const [arrItemOpen, setArrItemOpen] = useState([]);

  const modalCloseContacto = () => setShowModalContacto(false);
  const openModalContacto = (item) => {

    const newArray = [item]; // Crear una copia del array actual
   // newArray.push(item); // Agregar el nuevo elemento a la copia del array
    setArrItemOpen(newArray);
    
    setShowModalContacto(true);
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `http://sd-1501408-h00022.ferozo.net/api/company/${id}`
      );
      const json = await response.json();
      setEmpresa(json.data);

      if (empresa) {
        const phones = json.data.phones;
        for (let index = 0; index < phones.length; index++) {
          const element = phones[index];
          if (element.phone_type_id === "2") {
            setCelular(element.phone_number);
          }

          if (element.phone_type_id === "1") {
            setTelefono(element.phone_number);
          }

          if (element.phone_type_id === "6") {
            setWhatsapp(element.phone_number);
          }
        }

        setWeb(json.data.web);
      }
    };

    fetchData();
  }, []);

  function goWhatsApp(event){
    event.preventDefault();
    window.open(`https://api.whatsapp.com/send?phone=${whatsapp}`, '_blank');
  }
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1    
  };

  return (
    <div className="container empresa">
      <ModalContacto show={showModalContacto} hide={modalCloseContacto} type="empresas" items={arrItemOpen} />
      
      <div className="row">
        <div className="col-12">
          <Title text={empresa.name} />
        </div>
        <div className="col-md-8">
          <div className="card-info">
            <div className="info">
              <div className="title">
                <h3>
                  <i className="icon icon-user"></i> Perfil de la empresa
                </h3>
              </div>

              <ul>
                <li>
                  <i className="icon icon-location"></i> {empresa.street_name}{" "}
                  {empresa.street_number}
                </li>

                {telefono && (
                  <li>
                    <i className="icon icon-phone"></i> Tel: {telefono}
                  </li>
                )}

                {celular && (
                  <li>
                    <i className="icon icon-cel"></i> Cel: {celular}
                  </li>
                )}

                {web && (
                  <li>
                    <i className="icon icon-web"></i> {web}
                  </li>
                )}
              </ul>

              <div className="destacado">
                <button className="btn btn-mail" onClick={() => openModalContacto(empresa)}>
                <div>
                  <i className="icon icon-mail"></i>
                  <span>Enviar mail</span>
                  </div>
                </button>

                {whatsapp && (
                  <button className="btn btn-whatsapp" onClick={goWhatsApp}>
                    <div>
                    <i className="icon icon-whatsapp"></i>
                    <span>Whatsapp</span>
                    </div>
                  </button>
                )}

              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
            <Slider className="slider-empresa" {...settings}>
                {empresa.photo1_url && (
                <div>
                    <img alt='img' src={empresa.photo1_url}/>
                </div>
                )}

                {empresa.photo2_url && (
                <div>
                    <img alt='img' src={empresa.photo2_url}/>
                </div>
                )}

                {empresa.photo3_url && (
                <div>
                    <img alt='img' src={empresa.photo3_url}/>
                </div>
                )}
            </Slider>
        </div>
      </div>
    </div>
  );
};

export default Empresa;
