import './Banner.scss';

const Banner = (props) => {
    return(
        <div className="content-banner col-12">
           
           <a href={props.link} target="_blank">
            <img src={props.image} alt="Banner" />
            </a>
        </div>
    )
}

export default Banner;