import { Component } from 'react'
import { Link} from 'react-router-dom';
import React, { useState } from 'react';
import Youtube from '../../assets/img/icon-youtube.svg';
import Facebook from '../../assets/img/icon-facebook.svg';
import Twitter from '../../assets/img/icon-twitter.svg';
import Instagram from '../../assets/img/icon-instagram.svg';
import Linkedin from '../../assets/img/icon-linkedin.svg';
import './Footer.scss';

const Footer = () => {

  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [respuesta, setRespuesta] = useState(true);

  const validateEmail = (email) => {
    // Expresión regular para validar el formato del correo electrónico
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setIsValidEmail(false);
      setRespuesta('El formato del e-mail no es correcto.');
      return;
    }

    try {
      setIsValidEmail(true);
      const response = await fetch('http://sd-1501408-h00022.ferozo.net/api/newsletter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      console.log(data); // Maneja la respuesta de la API según tus necesidades
      if(data.status == "ok"){ 
        setEmail('');
        setRespuesta(data.message);
      }

      if(data.status == "error"){ 
      //  console.log(data.errors.email[0]);
        setRespuesta(data.errors.email[0]);
      }

      //setRespuesta(data.message);

    } catch (error) {
      console.error(error);
    }
  };

    return(
      <footer>
        <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
            <ul className='navList'>
              <li>
                <Link to='../sobre-nosotros' className='item-link'>SOBRE NOSOTROS</Link>
              </li>
              <li>
                <Link to='../rubros' className='item-link'>TODOS LOS RUBROS</Link>
              </li>
              <li>
                <Link to='../marcas' className='item-link'>TODOS LAS MARCAS</Link>  
              </li>
              <li><a className='item-link'>REVISTA DIGITAL</a></li>
              <li><a className='item-link'>MAPA DE WARNES</a></li>
              <li><a className='item-link'>BLOG</a></li>
              <li>
                <Link to='../contacto' className='item-link'>CONTACTO</Link>  
              </li>
            </ul>
          </div>
          <div className='col-md-4'>
            <div className='form-suscription'>
              <p>Suscribite a nuestro newsletter y enterate primero de todas las novedades del rubro automotor.</p>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="E-mail"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <button type="submit">Suscribirme</button>
              </form>
              <span className="response">{respuesta}</span>
            </div>
            
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-4'>

            <div className='social'>
              <p>Seguinos en</p>
              <ul>
                <li><a><img src={Facebook}/></a></li>
                <li><a><img src={Youtube}/></a></li>
                <li><a><img src={Twitter}/></a></li>
                <li><a><img src={Instagram}/></a></li>
                <li><a><img src={Linkedin}/></a></li>
              </ul>
            </div>

            <div className='legal'>
              <p>El Toque de Warnes asesora, informa y contribuye con el usuario; no se hace responsable por las actitudes y negociaciones entre las partes.<br/><br/>Registro Nacional de la Propiedad Intelectual - Nro. 75325985<br/><br/>Copyright © El Toque de Warnes 2021</p>
            </div>
          </div>
        </div>
        </div>
      </footer>
      )
  
}

export default Footer;