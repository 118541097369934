import React from "react";
import { Link } from "react-router-dom";


const Pagination = ({ firstPageUrl, current_page, lastPage, lastPageUrl, slug, onPageChange }) => {
  const pages = [];

  // Generar un arreglo con los números de página
  for (let i = 1; i <= lastPage; i++) {
    pages.push(i);
  }

  const handlePageChange = (page) => {
    // Llamar a la función onPageChange del componente padre
    onPageChange(page);
  };


  return (
    <nav className="pagination">
      <ul className="pagination">
        {pages.map((page) => (
          <li key={page} className="page-item">
            <Link 
              className={
                page === current_page ? "page-link active" : "page-link"
              }
              key={page}
              to={`?page=${page}`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;