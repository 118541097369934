import './Title.scss';

const Title = (params) => {
    return(
        <div>
            <div className="col-12 ">
                <div className="title-component">
                    {!params.subtext && (
                        <h1>{params.text}</h1>
                        )}

                    {params.subtext && (
                        <div>
                        <h2>{params.text} </h2>
                        <h3>{params.subtext}</h3>
                        </div>
                    )}
                </div>
            </div>
            <div className="col-12">
                    {params.subtext && (
                    <div className="separate mt-1 mb-5"></div>
                    )}

                    {!params.subtext && (
                    <div className="separate mt-4 mb-5"></div>
                    )}
            </div>
        </div>
      )
  }


export default Title;